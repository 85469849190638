import { apiSlice } from '../../app/api/apiSlice';
import { Course } from '../../types/course/Course';
import { FAQ } from '../../types/faq/faq';
import { Global } from '../../types/global/global';
import {
  Certificate,
  CertificationLog,
} from '../../types/my-certifications/MyCertification';
import { getRegistrationToken } from '../../utils/getRegistrationToken';

const urlCourse = process.env.REACT_APP_COURSEAPI_URL;

export const myCertificationApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getMyCertifications: query<CertificationLog[], string>({
      query: userId => ({
        url: `database/certification_log__c/aggregate`,
        method: 'POST',
        body: [
          {
            $match: {
              player: userId,
            },
          },
          {
            $lookup: {
              from: 'player',
              localField: 'player',
              foreignField: '_id',
              as: 'p',
            },
          },
          {
            $unwind: '$p',
          },
          {
            $lookup: {
              from: 'certification__c',
              localField: 'certification',
              foreignField: '_id',
              as: 'c',
            },
          },
          {
            $unwind: '$c',
          },
          {
            $project: {
              _id: 1,
              created: 1,
              updated: 1,
              finished: 1,
              extra: 1,
              approved_date: 1,
              schedule_start: 1,
              schedule_finish: 1,
              status: 1,
              quiz_log: 1,
              'player._id': '$p._id',
              'player.name': '$p.name',
              'certification._id': '$c._id',
              'certification.title': '$c.title',
              'certification.certificate_type': '$c.certificate_type',
              'certification.crcp': '$c.crcp',
              'certification.modality': '$c.modality',
              'certification.legal_basis': '$c.legal_basis',
              'certification.activity': '$c.activity',
              'certification.content': '$c.content',
              'certification.voucher_validity': '$c.voucher_validity',
              'certification.certification_validity':
                '$c.certification_validity',
            },
          },
          {
            $facet: {
              p1: [
                {
                  $match: {
                    quiz_log: {
                      $exists: true,
                    },
                  },
                },
                {
                  $lookup: {
                    from: 'quiz_log',
                    localField: 'quiz_log',
                    foreignField: '_id',
                    as: 'quiz_log',
                  },
                },
                {
                  $unwind: '$quiz_log',
                },
              ],
              p2: [
                {
                  $match: {
                    quiz_log: {
                      $exists: false,
                    },
                  },
                },
              ],
            },
          },
          {
            $project: {
              all: {
                $concatArrays: ['$p1', '$p2'],
              },
            },
          },
          {
            $unwind: '$all',
          },
          {
            $replaceRoot: {
              newRoot: '$all',
            },
          },
          {
            $sort: {
              'quiz_log.started': -1,
            },
          },
        ],
      }),
    }),
    getGlobal: query<Global, { apiKey?: string }>({
      query: (args = {}) => ({
        url: `global`,
        method: 'GET',
        headers: {
          Authorization: getRegistrationToken(args.apiKey),
        },
      }),
    }),
    getFAQ: query<FAQ[], null>({
      query: () => ({
        url: `database/faq__c`,
        method: 'GET',
      }),
    }),
    getCertificationStyle: query<Certificate[], null>({
      query: () => ({
        url: `database/certification_style__c`,
        method: 'GET',
      }),
    }),
    getCourse: mutation<Course, string>({
      query: id => ({
        url: `${urlCourse}/courses/crcp-code/${id}`,
      }),
    }),
  }),
});

export const {
  useGetMyCertificationsQuery,
  useGetGlobalQuery,
  useGetFAQQuery,
  useGetCertificationStyleQuery,
  useGetCourseMutation,
} = myCertificationApiSlice;
